import $ from 'jquery';

export default function setupMyKubiclePage() {
  $(".load-more").click(function () {
    $.getScript($(this).attr('href'));
  });

  KUBE.Tabs.bindSelectedCoursesFilters();

  $('.too-many-tabs .title').click(() => {
    $('.too-many-tabs').reflow_section();
  });

  if($('#other-courses-section').length > 0) KUBE.Tabs.bindTabClickEvents();
}
