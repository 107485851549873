import { isProduction } from '../utils/env';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

export default function bindPricing() {
  updateCurrencyValues(initialCurrency());
  bindCurrencyDropdown();
}

export const PRICES = {
  EUR: {
    symbol: '€',
    personal: { monthly: '35', annual: '445' },
  },
  GBP: {
    symbol: '£',
    personal: { monthly: '29', annual: '383' },
  },
  USD: {
    symbol: '$',
    personal: { monthly: '39', annual: '468' },
  },
};

const PRODUCTION_PLANS = {
  EUR: {
    monthly: 'price_1HTq2R2gXYFlITyTJToJio1V', annual: 'price_1MbS2u2gXYFlITyTuHAg3S3g',
  },
  GBP: {
    monthly: 'price_1HTq2R2gXYFlITyTi1euRhkG', annual: 'price_1MbS3T2gXYFlITyTyKrqYV4d',
  },
  USD: {
    monthly: 'price_1HTq2R2gXYFlITyTmVgYyy8t', annual: 'price_1MbS3k2gXYFlITyTbNUxP2ST',
  },
};

const TEST_PLANS = {
  EUR: {
    monthly: 'price_1HSPj82gXYFlITyTCrAB9gly', annual: 'price_1MbNAy2gXYFlITyTg0WBDDsU',
  },
  GBP: {
    monthly: 'price_1HSPj92gXYFlITyTVm54gvG5', annual: 'price_1MbNDC2gXYFlITyTJIKx4q5n',
  },
  USD: {
    monthly: 'price_1HSPj92gXYFlITyTxnrE18Ah', annual: 'price_1MbNDC2gXYFlITyTSOlX4HNi',
  },
};

export const getStripePlan = (currency, interval) => {
  const plans = isProduction() ? PRODUCTION_PLANS : TEST_PLANS;
  return plans[currency][interval];
};

export const initialCurrency = function () {
  let currency = null;

  if (getLocalStorage('KUBE.currency')) currency = getLocalStorage('KUBE.currency');
  else currency = ipCurrency();

  if (['USD', 'GBP', 'EUR'].includes(currency)) return currency;

  return 'USD';
};

export const getCurrency = function () {
  const currency = $('#currency_select').val();

  if (['USD', 'GBP', 'EUR'].includes(currency)) return currency;

  return 'USD';
};

const ipCurrency = function () {
  return $('#currency_select').data('value');
};

const updateCurrencyValues = function (currency) {
  $('.currency-select').val(currency);
  $('.currency').text(PRICES[currency].symbol);
  setLocalStorage('KUBE.currency', currency);
};
const bindCurrencyDropdown = function () {
  $('.currency-select').on('change', function () {
    const selectedCurrency = $(this).find(':selected').val();
    updateCurrencyValues(selectedCurrency);
  });
};
