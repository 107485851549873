import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from 'jquery';
import 'lib/locales/i18n';

import trackUser from 'lib/scripts/user_event_tracking';

import SkillCheckContainer from 'components/SkillCheck/Containers/SkillCheckContainer';
import SkillCheckIcon from 'components/SkillCheck/Components/SkillCheckIcon';
import ExamDownload from 'components/Exam/Components/ExamDownload';
import Reports from 'components/Reports/Components/Reports';
import Collapsible from 'components/Common/Components/Collapsible';
import Exercise from 'components/Exercise/Exercise';
import SqlExercise from 'components/SqlExercise/SqlExercise';
import SqlSandbox from 'components/SqlExercise/SqlSandbox';
import KeyboardShortcuts from 'components/KeyboardShortcuts/Components/KeyboardShortcuts';
import LearnDashboard from 'components/LearnScreen/Components/LearnDashboard';
import LearningPath from 'components/LearningPaths/Components/LearningPath';
import LearningPaths from 'components/LearningPaths/Components/LearningPaths';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Project from 'components/Project/Components/Project';
import WebpackerReact from 'webpacker-react';
import setupMyKubiclePage from '../lib/scripts/my_kubicle';
import appsignal, { listenAndReportErrors } from '../lib/utils/errorReporting';
import setupCourseSelectionPage from '../lib/scripts/course_selection';
import route from '../lib/utils/routing';
import Content from '../components/Library/Components/Content';
import Subject from '../components/Library/Components/Subject';
import GlobalMetricsIcons from '../components/LearnScreen/Components/GlobalMetricsIcons';
import WelcomeScreen from '../components/WelcomeScreen/Components/WelcomeScreen';
import ExamSubmission from '../components/Exam/Components/ExamSubmission';
import BackgroundOperation from '../components/BackgroundOperation/Components/BackgroundOperation';
import Portal from '../components/Portal/Components/Portal';
import IndividualTrial from '../components/IndividualTrial/Components/IndividualTrial';
import bindPricing from '../lib/payments_page/pricing';
import RequestPasswordReset from '../components/RequestPasswordReset/Components/RequestPasswordReset';
import PasswordReset from '../components/PasswordReset/Components/PasswordReset';
import { bindPaymentDetails, bindSCAModals } from '../lib/payments_page/payment-details';
import SignInPanel from '../components/SignIn/SignInPanel';
import NewToKubicle from '../components/Common/Components/NewToKubicle';
import SemesterLearningPathSelection from '../components/Semester/Components/create/SemesterLearningPathSelection';
import SemesterMilestonesForm from '../components/Semester/Components/SemesterMilestonesForm';
import AccountDeactivatedPage from '../components/AccountDeactivations/AccountDeactivatedPage';
import CalendarPreferencesPage from '../components/CalendarPreference/CalendarPreferencesPage';
import LottieContainer from '../components/Common/Components/LottieContainer';
import Library from '../components/Library/Components/Library';
import MandatoryProfileData from "../components/MandatoryProfileData/Components/MandatoryProfileData";
import HorizontalNavbar from '../components/HorizontalNavbar/Components/HorizontalNavbar';

WebpackerReact.setup({
  SkillCheckContainer,
  SkillCheckIcon,
  ExamDownload,
  Reports,
  Collapsible,
  Exercise,
  SqlExercise,
  SqlSandbox,
  KeyboardShortcuts,
  VideoPlayer,
  LearnDashboard,
  LearningPath,
  LearningPaths,
  Course: Content,
  Subject,
  GlobalMetricsIcons,
  Project,
  WelcomeScreen,
  ExamSubmission,
  BackgroundOperation,
  Portal,
  IndividualTrial,
  RequestPasswordReset,
  SignInPanel,
  NewToKubicle,
  PasswordReset,
  SemesterLearningPathSelection,
  SemesterMilestonesForm,
  AccountDeactivatedPage,
  CalendarPreferencesPage,
  LottieContainer,
  Library,
  MandatoryProfileData,
  HorizontalNavbar,
});

$(document).ready(() => {
  try {
    trackUser();
    route('.my-kubicle', setupMyKubiclePage);
    route('.course-selections.new', setupCourseSelectionPage);
    route('.marketing-site.pricing', bindPricing);
    route('.payment-details.new', bindPaymentDetails);
    route('.setup-intents.show', bindSCAModals);
  } catch (error) {
    console.error(error);
    appsignal.sendError(error);
  }
});

listenAndReportErrors();
