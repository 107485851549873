import {
  getCurrency, getStripePlan, initialCurrency, PRICES,
} from './pricing';

import client from '../api/axiosClient';

export const bindPaymentDetails = () => {
  const $form = $('#payment_details_form');

  if ($form.length) {
    const cardElement = $form.find('#card_element')[0];

    const card = createCard(cardElement);

    $form.submit(async e => {
      e.preventDefault();

      handlePaymentFormSubmit($form[0], card);
    });

    $('.currency-select').val(initialCurrency());
    updateFormPrices();

    $form.find('#promo_code').on('keyup', e => {
      const $applyButton = $form.find('#apply_promo_code');

      if (e.target.value === '') $applyButton.addClass('hidden');
      else $applyButton.removeClass('hidden');
    });

    $form.find('#apply_promo_code').click(e => {
      const $button = $(e.target);
      const promoCode = $('#promo_code').val();

      if (promoCode && !$button.hasClass('disabled')) {
        const originalText = $button.text();
        $button.html('<i class="fa fa-spinner"></i>');
        $button.addClass('disabled');

        client
          .get('/promo_codes', { params: { promo_code: promoCode } })
          .then(({ data }) => {
            $('#discount_amount').val(data.discount_amount);
            $('#duration_in_months').val(data.duration_in_months);
            updateFormPrices();
            $button.html(originalText);
            $button.removeClass('disabled');

            if (data.discount_amount === 0) {
              KUBE.Alert.error(data.error_message);
              $('#promo_code').val('');
            }
          });
      }
    });

    $form.find('#currency_select, .subscription-options input').change(updateFormPrices);
  }
};

const updateFormPrices = () => {
  const billingInverval = $('input[name="billing_interval"]:checked').val();
  const currency = getCurrency();

  const { symbol, personal } = PRICES[currency];
  const originalPrice = personal[billingInverval];
  const discount = $('#discount_amount').val();
  const duration_in_months = $('#duration_in_months').val();

  const formattedOriginalPrice = symbol + originalPrice;
  const formattedPrice = symbol + (originalPrice - (originalPrice * discount));
  const zeroPrice = `${symbol}0`;

  $('.today-amount').text(zeroPrice);
  $('.subscription-amount').text(formattedPrice);
  $('.after-trial .price, .smallprint-charging').addClass('hidden');
  $(`.${billingInverval}-price, .smallprint-charging-${billingInverval}`).removeClass('hidden');
  $('#stripe_price_id').val(getStripePlan(currency, billingInverval));

  if (discount > 0) {
    $('.original-amount').text(formattedOriginalPrice);
    $('.original-price').removeClass('hidden');
    $('.coupon-duration').addClass('hidden');
    if (duration_in_months > 0) {
      $('.coupon-duration').removeClass('hidden');
      $('.coupon-unlimited').addClass('hidden');
      $('.coupon-limited').removeClass('hidden');
      $('.months-limit').text(duration_in_months);
    } else {
      $('.coupon-unlimited').removeClass('hidden');
      $('.coupon-limited').addClass('hidden');
    }
    KUBE.Alert.success('Your discount code has been applied!');
  } else {
    $('.coupon-duration').addClass('hidden');
    $('.original-price').addClass('hidden');
  }
};

export const bindSCAModals = () => {
  try {
    if (typeof STRIPE_PAYMENT_INTENT_SECRET !== 'undefined') {
      stripe.confirmCardSetup(STRIPE_PAYMENT_INTENT_SECRET).then(result => {
        if (result.error) showConfirmationError();
        else redirectToThankYou();
      });
    }
  } catch (e) {
    redirectToThankYou();
    if (e instanceof ReferenceError) console.log(e.message);
    else throw e;
  }
};

const showConfirmationError = () => {
  $('#confirmation_required_message').addClass('hidden');
  $('#confirmation_failed_message').removeClass('hidden');
};

const redirectToThankYou = () => {
  window.location.href = '/welcome';
};

function createCard(cardElement) {
  const elements = stripe.elements();

  const style = {
    base: {
      fontSize: '20px', color: '#5c5c5c', fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    },
    invalid: {
      color: '#D43008', iconColor: '#D43008',
    },
  };

  const card = elements.create('card', { style });
  card.mount(cardElement);

  card.addEventListener('change', ({ error }) => {
    const displayError = document.getElementById('card_errors');
    const cardElement = document.getElementById('card_element');

    if (error) {
      displayError.textContent = error.message;
      addErrorClass(cardElement);
    } else {
      displayError.textContent = '';
      removeErrorClass(cardElement);
    }
  });

  return card;
}

const handlePaymentFormSubmit = async (form, card) => {
  const $joinButton = $('#kube-join-button');
  $joinButton.attr('disabled', 'disabled');
  const { token, error } = await stripe.createToken(card);

  if (error) {
    const errorElement = document.getElementById('card_errors');
    errorElement.textContent = error.message;
    $joinButton.attr('disabled', false);
  } else {
    stripeTokenHandler(token, form);
  }
};

const stripeTokenHandler = (token, form) => {
  const hiddenInput = document.getElementById('stripe_token');
  hiddenInput.setAttribute('value', token.id);
  form.submit();
};

const addErrorClass = (formField) => {
  const $formField = $(formField);
  $formField.addClass('error');
  let $messageContainer = $formField.next('.error-message-container');
  if ($messageContainer.length === 0) $messageContainer = $formField.closest('.field-container').find('.error-message-container');

  $messageContainer.addClass('visible');
};

const removeErrorClass = (formField) => {
  const $formField = $(formField);
  $formField.removeClass('error');
  let $messageContainer = $formField.next('.error-message-container');
  if ($messageContainer.length === 0) $messageContainer = $formField.closest('.field-container').find('.error-message-container');

  $messageContainer.removeClass('visible');
};
